import {Tokens} from './tokens-theme.d';

export const tokens: Tokens = {
  COLOR_BACKGROUND_BODY: '#FFFFFF',
  COLOR_BACKGROUND_PRIMARY: '#FFFFFF',
  COLOR_BACKGROUND_SECONDARY: '#F0F1F4',
  COLOR_BACKGROUND_TERTIARY: '#E7E9ED',
  COLOR_BACKGROUND_INVERSE_PRIMARY: '#12101A',
  COLOR_BACKGROUND_INVERSE_SECONDARY: '#211F30',
  COLOR_BACKGROUND_SCRIM: 'rgba(0, 0, 0, 0.5)',
  COLOR_BACKGROUND_BLACK_TRANSPARENT: 'rgba(0, 0, 0, 0.6)',
  COLOR_BACKGROUND_SUBDUED_NEGATIVE: '#FFDCDC',
  COLOR_BACKGROUND_NEGATIVE: '#CA0000',
  COLOR_BACKGROUND_SUBDUED_WARNING: '#FFEBD1',
  COLOR_BACKGROUND_WARNING: '#FF9815',
  COLOR_BACKGROUND_SUBDUED_POSITIVE: '#D1F2E2',
  COLOR_BACKGROUND_POSITIVE: '#2FA86D',
  COLOR_BACKGROUND_SUBDUED_INFORMATIONAL: '#E9EAFF',
  COLOR_BACKGROUND_INFORMATIONAL: '#4951FF',
  COLOR_BACKGROUND_DISABLED: '#F0F1F4',
  COLOR_BACKGROUND_WHITE: '#FFFFFF',
  COLOR_BACKGROUND_INVERSE_DISABLED: '#2D2B41',
  COLOR_BACKGROUND_BLACK: '#12101A',
  COLOR_BACKGROUND_ORANGE: '#EE8B36',
  COLOR_ACTION_BRAND: '#E0FE2A',
  COLOR_ACTION_HOVER: 'rgba(0, 0, 0, 0.06)',
  COLOR_ACTION_PRESSED: 'rgba(0, 0, 0, 0.12)',
  COLOR_ACTION_INVERSE_HOVER: 'rgba(255, 255, 255, 0.08)',
  COLOR_ACTION_INVERSE_PRESSED: 'rgba(255, 255, 255, 0.16)',
  COLOR_BORDER_DEFAULT: '#ADB0BC',
  COLOR_BORDER_SUBDUED: '#E7E9ED',
  COLOR_BORDER_PRIMARY: '#12101A',
  COLOR_BORDER_INVERSE: '#52526E',
  COLOR_BORDER_INVERSE_SUBDUED: '#3D3C56',
  COLOR_BORDER_INVERSE_PRIMARY: '#FFFFFF',
  COLOR_BORDER_NEGATIVE: '#CA0000',
  COLOR_BORDER_WHITE: '#FFFFFF',
  COLOR_CONTENT_DEFAULT: '#211F30',
  COLOR_CONTENT_SUBDUED: '#6E7189',
  COLOR_CONTENT_DISABLED: '#C6C9D1',
  COLOR_CONTENT_INVERSE: '#FFFFFF',
  COLOR_CONTENT_INVERSE_SUBDUED: '#ADB0BC',
  COLOR_CONTENT_INVERSE_DISABLED: '#6E7189',
  COLOR_CONTENT_NEGATIVE: '#CA0000',
  COLOR_CONTENT_POSITIVE: '#2FA86D',
  COLOR_CONTENT_WARNING: '#B44616',
  COLOR_CONTENT_BRAND: '#E0FE2A',
  COLOR_CONTENT_WHITE: '#FFFFFF',
  COLOR_CONTENT_BLACK: '#12101A',
  COLOR_FOCUS_DEFAULT: 'rgba(18, 16, 26, 0.12)',
  COLOR_FOCUS_INVERSE: 'rgba(255, 255, 255, 0.32)',
};
