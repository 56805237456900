import {
  legacy_createStore as createStore,
  applyMiddleware,
  Store,
  AnyAction,
} from 'redux';
import {composeWithDevTools} from '@redux-devtools/extension';
import {createWrapper} from 'next-redux-wrapper';
import {NextPageContext} from 'next';
import rootReducer from './root-reducer';
import middlewares from '../middlewares';

// Add redux dev tools
const enhancer = composeWithDevTools(applyMiddleware(...middlewares));

export interface BrightPageContext extends NextPageContext {
  store: Store;
  ctx: NextPageContext;
}

/**
 * Create redux store
 *
 * @param   {Context}  context
 *
 * @return  {Store}
 */
// create a makeStore function
let store: Store<AnyAction>;
const makeStore = () => {
  store = createStore(rootReducer, enhancer);
  return store;
};

export {store};
// export an assembled wrapper
export const reduxWrapper = createWrapper(makeStore);
