import 'styled-components';
import {assign} from 'lodash';
import {tokens} from './tokens/tokens-light';
import {
  color,
  elevation,
  spacing,
  margin,
  borderRadius,
  borderWidth,
  fontSize,
  lineHeight,
  fontWeight,
  letterSpacing,
  fonts,
  sizing,
  opacity,
} from './theme-core';

const backgroundColor = {
  backgroundBody: tokens.COLOR_BACKGROUND_BODY,
  backgroundPrimary: tokens.COLOR_BACKGROUND_PRIMARY,
  backgroundSecondary: tokens.COLOR_BACKGROUND_SECONDARY,
  backgroundTertiary: tokens.COLOR_BACKGROUND_TERTIARY,
  backgroundInversePrimary: tokens.COLOR_BACKGROUND_INVERSE_PRIMARY,
  backgroundInverseSecondary: tokens.COLOR_BACKGROUND_INVERSE_SECONDARY,
  backgroundScrim: tokens.COLOR_BACKGROUND_SCRIM,
  backgroundBlackTransparent: tokens.COLOR_BACKGROUND_BLACK_TRANSPARENT,
  actionBrand: tokens.COLOR_ACTION_BRAND,
  backgroundSubduedNegative: tokens.COLOR_BACKGROUND_SUBDUED_NEGATIVE,
  backgroundNegative: tokens.COLOR_BACKGROUND_NEGATIVE,
  backgroundWarning: tokens.COLOR_BACKGROUND_WARNING,
  backgroundSubduedPositive: tokens.COLOR_BACKGROUND_SUBDUED_POSITIVE,
  backgroundPositive: tokens.COLOR_BACKGROUND_POSITIVE,
  backgroundSubduedInformational: tokens.COLOR_BACKGROUND_SUBDUED_INFORMATIONAL,
  backgroundInformational: tokens.COLOR_BACKGROUND_INFORMATIONAL,
  backgroundDisabled: tokens.COLOR_BACKGROUND_DISABLED,
  backgroundBlack: tokens.COLOR_BACKGROUND_BLACK,
  backgroundWhite: tokens.COLOR_BACKGROUND_WHITE,
  backgroundOrange: tokens.COLOR_BACKGROUND_ORANGE,
  backgroundInverseDisabled: tokens.COLOR_BACKGROUND_INVERSE_DISABLED,
  actionHover: tokens.COLOR_ACTION_HOVER,
  actionInverseHover: tokens.COLOR_ACTION_INVERSE_HOVER,
  actionPressed: tokens.COLOR_ACTION_PRESSED,
  actionInversePressed: tokens.COLOR_ACTION_INVERSE_PRESSED,
  backgroundTransparent: 'transparent',
};

const contentColor = {
  contentDefault: tokens.COLOR_CONTENT_DEFAULT,
  contentSubdued: tokens.COLOR_CONTENT_SUBDUED,
  contentDisabled: tokens.COLOR_CONTENT_DISABLED,
  contentInverse: tokens.COLOR_CONTENT_INVERSE,
  contentInverseSubdued: tokens.COLOR_CONTENT_INVERSE_SUBDUED,
  contentInverseDisabled: tokens.COLOR_CONTENT_INVERSE_DISABLED,
  contentNegative: tokens.COLOR_CONTENT_NEGATIVE,
  contentPositive: tokens.COLOR_CONTENT_POSITIVE,
  contentWarning: tokens.COLOR_CONTENT_WARNING,
  contentBrand: tokens.COLOR_CONTENT_BRAND,
  contentWhite: tokens.COLOR_CONTENT_WHITE,
  contentBlack: tokens.COLOR_CONTENT_BLACK,
};

const borderColor = {
  borderDefault: tokens.COLOR_BORDER_DEFAULT,
  borderSubdued: tokens.COLOR_BORDER_SUBDUED,
  borderPrimary: tokens.COLOR_BORDER_PRIMARY,
  borderInverse: tokens.COLOR_BORDER_INVERSE,
  borderInverseSubdued: tokens.COLOR_BORDER_INVERSE_SUBDUED,
  borderInversePrimary: tokens.COLOR_BORDER_INVERSE_PRIMARY,
  borderWhite: tokens.COLOR_BORDER_WHITE,
  borderNegative: tokens.COLOR_BORDER_NEGATIVE,
};

const focusColor = {
  focus: tokens.COLOR_FOCUS_DEFAULT,
  focusInverse: tokens.COLOR_FOCUS_INVERSE,
};

const theme = assign(
  {color},
  {backgroundColor},
  {contentColor},
  {borderColor},
  {focusColor},
  {elevation},
  {spacing},
  {margin},
  {borderRadius},
  {borderWidth},
  {fontSize},
  {lineHeight},
  {fontWeight},
  {letterSpacing},
  {fonts},
  {sizing},
  {opacity}
);

export {theme};
