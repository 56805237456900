import fetch, {RequestInit, BodyInit} from 'node-fetch';
import axios from 'axios';
import {getCookie} from './helpers/cookie';
import firebase from 'firebase/compat/app';
/* eslint-disable  @typescript-eslint/no-explicit-any */

const isServer = typeof window === 'undefined';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export class AuthWrapper {
  promise: (authToken: string) => Promise<any>;
  constructor(promise: (authToken: string) => Promise<any>) {
    this.promise = promise;
  }
  async execute(authToken = '') {
    return await this.promise(authToken);
  }
  async executeState(state: any) {
    return await this.execute(state?.auth?.auth?.authToken);
  }
}

/**
 * API
 */
class Api {
  /**
   * Set default headers and Authorization header for api calls
   *
   * @return  {object} headers object
   */
  static headers() {
    return Object.assign({}, defaultHeaders, {
      'X-API-KEY': process.env.NEXT_PUBLIC_AUTHORIZATION,
    });
  }
  /**
   * [route description]
   *
   * @param   {string}  route   [route description]
   * @param   {Record<string, string>}  params  [params description]
   *
   * @return  {[type]}          [return description]
   */
  static get(route: string, params?: Record<string, string>) {
    return this.xhr(route, params, 'GET');
  }

  /**
   * PATCH API call
   *
   * @param   {string}  route   [route description]
   * @param   {Record<string, string>}  params  [params description]
   *
   * @return  {[type]}          [return description]
   */
  static patch(route: string, params?: Record<string, string | boolean>) {
    return this.xhr(route, params, 'PATCH');
  }

  /**
   * [put description]
   *
   * @param   {string}  route   [route description]
   * @param   {Record<string, string>}  params  [params description]
   *
   * @return  {[type]}          [return description]
   */
  static put(route: string, params?: Record<string, string>) {
    return this.xhr(route, params, 'PUT');
  }

  /**
   * [post description]
   *
   * @param   {string}  route   [route description]
   * @param   {Record<string, string>}  params  [params description]
   *
   * @return  {[type]}          [return description]
   */
  static post(route: string, params?: Record<string, string | boolean>) {
    return this.xhr(route, params, 'POST');
  }

  /**
   * [delete description]
   *
   * @param   {string}  route   [route description]
   * @param   {Record<string, string>}  params  [params description]
   *
   * @return  {[type]}          [return description]
   */
  static delete(route: string, params?: Record<string, string>) {
    return this.xhr(route, params, 'DELETE');
  }

  /**
   * [postMultipart description]
   *
   * @param   {string}  route   [route description]
   * @param   {Record<string, string>}  params  [params description]
   *
   * @return  {[type]}          [return description]
   */
  static postMultipart(route: string, params: Record<string, string>) {
    return this.xhrMulti(route, params);
  }

  /**
   * [postFormData description]
   *
   * @param   {string}  route     [route description]
   * @param   {FormData}  formData  [formData description]
   *
   * @return  {[type]}            [return description]
   */
  static postFormData(route: string, formData: FormData) {
    return this.xhrFormData(route, formData);
  }

  /**
   * [postFormData description]
   *
   * @param   {string | undefined}  token     [firebase token]
   *
   * @return  {[type]}            [return description]
   */
  static async fetchCurrentUser(token: string | undefined, manager = false) {
    const currentUserHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-API-KEY': process.env.NEXT_PUBLIC_AUTHORIZATION,
      Authorization: `Bearer ${token}`,
    };
    let query = '?creator=true';
    if (manager) query += '&manager=true';
    const currentUser = await axios({
      method: 'get',
      url: `${process.env.NEXT_PUBLIC_HOST_URL}/users/current${query}`,
      headers: currentUserHeaders,
    });

    return currentUser.data;
  }

  /**
   * [postFormData description]
   *
   *
   * @return  {[type]}            [return description]
   */
  static async userLogout(authToken: string) {
    const currentUserHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-API-KEY': process.env.NEXT_PUBLIC_AUTHORIZATION,
      Authorization: authToken,
    };
    const expiredTime = Date.now();
    const currentUser = await axios({
      method: 'get',
      url: `${process.env.NEXT_PUBLIC_CF_LOGOUT_URL}?timestamp=${expiredTime}`,
      headers: currentUserHeaders,
      withCredentials: true,
    });

    return currentUser.data;
  }

  /**
   * [xhr description]
   *
   * @param   {string}  route   [route description]
   * @param   {Record<string, string>}  params  [params description]
   * @param   {string}  verb    [verb description]
   *
   * @return  {[type]}          [return description]
   */
  static xhr(
    route: string,
    params?: Record<string, string | boolean>,
    verb?: string
  ) {
    return new AuthWrapper(async (authToken: string) => {
      const options = Object.assign({method: verb});
      options.headers = this.headers();
      options.headers['Authorization'] = `Bearer ${authToken}`;
      params = params ? params : {};

      let query;
      let url;
      if (verb === 'GET') {
        query = this.getQuery(params);
        !query
          ? (url = `${process.env.NEXT_PUBLIC_HOST_URL}${route}`)
          : (url = `${process.env.NEXT_PUBLIC_HOST_URL}${route}?${query}`);
      } else {
        options.body = JSON.stringify(params);
        url = `${process.env.NEXT_PUBLIC_HOST_URL}${route}`;
      }

      let response = await fetch(url, options);
      let data = await response.json();

      // If API request fails on the client side with unauthorized error, refresh the user auth and try again
      if (
        !isServer &&
        options.headers['Authorization'] &&
        response.status === 401
      ) {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
          authToken = await currentUser.getIdToken();
          options.headers['Authorization'] = `Bearer ${authToken}`;
          response = await fetch(url, options);
          data = await response.json();
        }
      }
      if (!response.ok) throw data;

      return data;
    });
  }

  /**
   * [xhrMulti description]
   *
   * @param   {string}  route   [route description]
   * @param   {Record<string, any>}  params  [params description]
   *
   * @return  {[type]}          [return description]
   */
  static xhrMulti(route: string, params?: Record<string, any>) {
    return new AuthWrapper(async (authToken: string) => {
      const form = new FormData();

      for (const key in params) {
        if (key === 'files') {
          params[key].forEach((file, i) => {
            form.append(`file[${i}]`, file);
          });
        } else {
          form.append(key, params[key]);
        }
      }

      const body: BodyInit = form as BodyInit;

      const options: RequestInit = {
        method: 'POST',
        headers: {
          Authorization: authToken,
        },
        body: body,
      };

      const url = `${process.env.NEXT_PUBLIC_HOST_URL}${route}`;

      return fetch(url, options)
        .then(resp => {
          const json = resp.json();
          if (resp.ok) {
            return json;
          }
          return json.then(err => {
            throw err;
          });
        })
        .then((json: unknown) => {
          return json;
        });
    });
  }
  /**
   * [xhrFormData description]
   *
   * @param   {string}  route     [route description]
   * @param   {FormData}  formData  [formData description]
   *
   * @return  {[type]}            [return description]
   */
  static xhrFormData(route: string, formData: FormData) {
    return new AuthWrapper(async (authToken: string) => {
      const options: RequestInit = {
        method: 'POST',
        body: formData as BodyInit,
        headers: {
          Authorization: authToken,
        },
      };

      const url = `${process.env.NEXT_PUBLIC_HOST_URL}${route}`;

      return fetch(url, options)
        .then(resp => {
          const json = resp.json();
          if (resp.ok) {
            return json;
          }
          return json.then(err => {
            throw err;
          });
        })
        .then((json: unknown) => {
          return json;
        });
    });
  }

  /**
   * [getQuery description]
   *
   * @param   {Record<string, string>}  params  [params description]
   *
   * @return  {[type]}          [return description]
   */
  static getQuery(params: Record<string, string | boolean>) {
    return Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
  }

  /**
   * [getCSRF description]
   *
   * @return  {[type]}  [return description]
   */
  static getCSRF() {
    return getCookie('xsrf-token');
  }

  /**
   * [get calendly meeting info]
   *
   * @param   {string}  url     [url]
   *
   * @return  {[type]}            [return data]
   */
  static getCalendlyMeeting(url: string) {
    return new AuthWrapper(async (authToken: string) => {
      const options = Object.assign({method: 'get'});
      options.headers = this.headers();
      options.headers['Authorization'] = `Bearer ${authToken}`;
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_HOST_URL}/creators/calendly_scheduled_event?eventUrl=${url}`,
        options
      );
      const data = await response.json();
      return data;
    });
  }
}

export default Api;
